import React, { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from the backend
    axios.get('https://liqatradingandmanufacturing.com/api/data')
      .then(response => {

        setData(response.data);

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <>
      <h1>Your React App</h1>
      {/* Display your data in the component */}
     
      {/* {data.map(item => (
        <div key={item.id}>{item.name} {item.phone}</div>
      ))} */}

     {Array.isArray(data) ? (
  data.map(item => (
    // Your code here
    <div key={item.id}>{item.name} {item.phone}</div>
  ))
) : (
  <p>The variable is not an array.</p>
)}
    </>
   
  );
}

export default App;
